<template>
  <LandingPageLayout>
    <HeadingBanner
      :title1="$t('landing_page.footer.who_academy')"
      :title2="$t('landing_page.footer.accessibility_statement')"
      :isTitleBreakdown="true"
    />
    <section class="accessibility__statement--container">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="heading">
              {{ $t("accessibility_statement.statement1.heading") }}
            </div>
            <div class="content">
              <div class="paragraph">
                {{ $t("accessibility_statement.statement1.content1") }}
              </div>
              <div class="paragraph">
                {{ $t("accessibility_statement.statement1.content2") }}
              </div>
              <ol class="list">
                <li>
                  <span class="bold">
                    {{
                      $t(
                        "accessibility_statement.statement1.list_items.item1.title"
                      )
                    }}
                  </span>
                  {{
                    $t(
                      "accessibility_statement.statement1.list_items.item1.content"
                    )
                  }}
                </li>
                <li>
                  <span class="bold">
                    {{
                      $t(
                        "accessibility_statement.statement1.list_items.item2.title"
                      )
                    }}
                  </span>
                  {{
                    $t(
                      "accessibility_statement.statement1.list_items.item2.content"
                    )
                  }}
                </li>
                <li>
                  <span class="bold">
                    {{
                      $t(
                        "accessibility_statement.statement1.list_items.item3.title"
                      )
                    }}
                  </span>
                  {{
                    $t(
                      "accessibility_statement.statement1.list_items.item3.content"
                    )
                  }}
                </li>
                <li>
                  <span class="bold">
                    {{
                      $t(
                        "accessibility_statement.statement1.list_items.item4.title"
                      )
                    }}
                  </span>
                  {{
                    $t(
                      "accessibility_statement.statement1.list_items.item4.content"
                    )
                  }}
                </li>
                <li>
                  <span class="bold">
                    {{
                      $t(
                        "accessibility_statement.statement1.list_items.item5.title"
                      )
                    }}
                  </span>
                  {{
                    $t(
                      "accessibility_statement.statement1.list_items.item5.content"
                    )
                  }}
                </li>
                <li>
                  <span class="bold">
                    {{
                      $t(
                        "accessibility_statement.statement1.list_items.item6.title"
                      )
                    }}
                  </span>
                  {{
                    $t(
                      "accessibility_statement.statement1.list_items.item6.content"
                    )
                  }}
                </li>
                <li>
                  <span class="bold">
                    {{
                      $t(
                        "accessibility_statement.statement1.list_items.item7.title"
                      )
                    }}
                  </span>
                  {{
                    $t(
                      "accessibility_statement.statement1.list_items.item7.content"
                    )
                  }}
                </li>
              </ol>
            </div>
            <div class="heading">
              {{ $t("accessibility_statement.statement2.heading") }}
            </div>
            <div class="content">
              <i18next
                path="accessibility_statement.statement2.content"
                tag="div"
                class="paragraph"
              >
                <a href="mailto:academysupport@who.int" target="_blank">
                  academysupport@who.int
                </a>
              </i18next>
            </div>
          </div>
        </div>
      </div>
    </section>
    <StayTuned />
  </LandingPageLayout>
</template>

<script>
import LandingPageLayout from "@/components/Layout/LandingPageLayout.vue";
import HeadingBanner from "@/components/Landing/HeadingBanner.vue";
import StayTuned from "@/components/Landing/StayTuned.vue";
export default {
  components: {
    LandingPageLayout,
    HeadingBanner,
    StayTuned
  }
};
</script>
<style lang="scss" scoped>
.accessibility__statement--container {
  font-family: $font-family;
  text-align: left;
  padding: 64px 0px 128px;
  > .container {
    max-width: 800px;
  }
  .heading {
    @include subtitle-large;
    @include gradient-text;
    letter-spacing: 0.055px;
    &:not(:first-of-type) {
      margin-top: 48px;
    }
  }
  .content {
    margin-top: 16px;
    @include subtitle-regular;
    font-weight: 400;
    color: $brand-neutral-1000;
    .paragraph {
      &:not(:first-child) {
        margin-top: 24px;
      }
      + .list {
        margin-top: 24px;
      }
    }
    .list {
      margin: 0;
      padding-left: 15px;
      li {
        &:not(:first-child) {
          margin-top: 4px;
        }
      }
    }
    .bold {
      font-weight: 700;
    }
  }
  @media only screen and (min-width: 768px) {
    > .container {
      max-width: 704px;
    }
  }
  @media only screen and (min-width: 1200px) {
    > .container {
      max-width: 824px;
    }
  }
  @media only screen and (max-width: 767px) {
    padding: 32px 0px;
    > .container {
      max-width: 312px;
    }
  }
}
</style>
