<template>
  <section class="heading__banner--section">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="heading" v-if="isTitleBreakdown">
            {{ title1 }}<br />
            {{ title2 }}
          </div>
          <div class="heading" v-else>
            {{ title }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    title: String,
    title1: String,
    title2: String,
    isTitleBreakdown: {
      type: Boolean,
      default: false
    }
  }
};
</script>
<style lang="scss" scoped>
.heading__banner--section {
  height: 300px;
  background: $radial-gradient-bg;
  @include flex-horizontal-center;
  padding-top: 64px;
  .heading {
    @include title-large;
    color: $brand-neutral-0;
    text-transform: capitalize;
  }
  @media only screen and (min-width: 768px) {
    > .container {
      max-width: 710px;
    }
  }
  @media only screen and (min-width: 1200px) {
    > .container {
      max-width: 798px;
    }
  }
  @media only screen and (max-width: 767px) {
    height: 280px;
    > .container {
      max-width: 286px;
    }
    .heading {
      font-size: 32px;
      line-height: 40px;
      letter-spacing: 0.08px;
    }
  }
}
</style>
